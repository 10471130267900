<template>
  <div class="container">
    <!-- eslint-disable-next-line vue/html-self-closing -->
    <div class="content">
      <!-- eslint-disable-next-line vue/html-self-closing -->
      <div
        class="text-container"
        v-html="t('intro')"
      ></div>
      <div class="arrow-container">
        <div class="arrow">
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <div class="line"></div>
          <!-- eslint-disable-next-line vue/html-self-closing -->
          <div class="point"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, watch } from 'vue';

import messaging from '@/services/messaging';
import * as mews from '@/services/mews';
import { changeLanguage, t } from '@/i18n';

export default defineComponent({
  name: 'App',
  components: {},
  setup() {
    const hotel = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        const v = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'hotel')
          .shift();
        if (v) {
          return v[1];
        }
      }
      return 'dev';
    });

    const keyCutterId = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        const v = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'keyCutterId')
          .shift();
        if (v) {
          return v[1];
        }
      }
      return 'no-keycutter-id';
    });

    const reservationId = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        const v = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'reservationId')
          .shift();
        if (v) {
          return v[1];
        }
      }
      return 'no-reservation-id';
    });

    const keyCount = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        const v = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'keyCount')
          .shift();
        if (v) {
          return parseInt(v[1], 10) || 1;
        }
      }
      return 1;
    });

    const language = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        let l = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'systemLanguage')?.shift();

        if (l) {
          l = l[1].toLowerCase();
          if (['ger', 'eng', 'fre'].includes(l)) {
            return l;
          }
        }
      }
      return 'ger'; // fallback
    });

    const useDev = computed(() => {
      const uri = window.location.href.split('?');
      if (uri.length === 2) {
        const params = uri[1].split('&');
        const v = params.map((p) => p.split('='))
          .filter((p) => p.length === 2)
          .filter(([key]) => key === 'dev')
          .shift();
        if (v) {
          return true;
        }
      }
      return false;
    });

    watch(language, () => {
      changeLanguage(language.value);
    }, { immediate: true });

    onMounted(async () => {
      try {
        const result = await mews.requestCommand({
          hotel: hotel.value,
          keyCutterId: keyCutterId.value,
          reservationId: reservationId.value,
          keyCount: keyCount.value,
          dev: useDev.value,
        });
        if (result.data.error) {
          messaging.send('set_variable', {
            status: 'Error',
          });
        } else {
          messaging.send('set_variable', {
            status: result.data.result,
          });
        }
      } catch (err) {
        messaging.send('set_variable', {
          status: 'Error',
        });
      }
      messaging.send('close');
    });

    return {
      t,
    };
  },
});
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 50px;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 4%;
  line-height: 3ch;
}

.text-container {
  text-align: center;
  width: 100%;
  margin-bottom: 100px;
}

.arrow-container {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 200px;
}

.arrow {
  width: 120px;
  transform: rotate(-225deg);
}

.line {
  margin-top: 14px;
  width: 90px;
  background: black;
  height: 10px;
  float: left;
}

.point {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 30px solid black;
  float: right;
}
</style>
